import React from "react";
import { Link } from "gatsby";

import Scaffold from "../../components/portfolio";
import clarityScreenshot from "../../images/clarity.png";
import gladstoneScreenshot from "../../images/gladstone.png";
import moviesScreenshot from "../../images/movies.png";

const PortfolioIndex = (props) => {
  return (
    <Scaffold pathname={props.location.pathname}>
      <h2>Portfolio</h2>

      <Link
        to="/portfolio/clarity/"
        style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.8)" }}
      >
        <div
          style={{ display: "flex", flexDirection: "row" }}
          className="browser-mockup-collection"
        >
          <div style={{ width: 294 }}>
            <div className="browser-mockup">
              <img
                style={{ maxWidth: "100%", width: 294, height: 147 }}
                src={clarityScreenshot}
              />
            </div>
          </div>
          <div style={{ margin: 18, flex: 1 }}>
            <h3 style={{ color: "black" }}>Clarity Coach</h3>
            <p>
              Clarity Coach is a startup I founded which uses Machine Learning
              to analyse eSport matches.
            </p>
          </div>
        </div>
      </Link>

      <Link
        to="/portfolio/design/"
        style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.8)" }}
      >
        <div
          style={{ display: "flex", flexDirection: "row" }}
          className="browser-mockup-collection"
        >
          <div style={{ width: 294 }}>
            <div className="browser-mockup">
              <img
                style={{ maxWidth: "100%", width: 294, height: 147 }}
                src={gladstoneScreenshot}
              />
            </div>
          </div>
          <div style={{ margin: 18, flex: 1 }}>
            <h3 style={{ color: "black" }}>Website Design</h3>
            <p>
              Freelance website design and development I've done for various
              clients.
            </p>
          </div>
        </div>
      </Link>

      <Link
        to="/portfolio/movie/"
        style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.8)" }}
      >
        <div
          style={{ display: "flex", flexDirection: "row" }}
          className="browser-mockup-collection"
        >
          <div style={{ width: 294 }}>
            <div className="browser-mockup">
              <img
                style={{ maxWidth: "100%", width: 294, height: 147 }}
                src={moviesScreenshot}
              />
            </div>
          </div>
          <div style={{ margin: 18, flex: 1 }}>
            <h3 style={{ color: "black" }}>Movie Analytics</h3>
            <p>
              App I wrote to explore, visualise, and analyse data from all the
              movies I've rated.
            </p>
          </div>
        </div>
      </Link>
    </Scaffold>
  );
};

export default PortfolioIndex;
